<template>
  <Menu label="profile" bottom-left class="mr-2">
    <template v-slot:title>
      <div v-if="appPage === 'Admin'">
        <img
          v-if="$OrgData.logoUrl"
          :src="appPage === 'ESS' ? $AuthUser.photo : $OrgData.logoUrl"
          alt="account"
          style="height: 35px; width: 35px; border-radius: 50%;"
        />
        <div
          style="height: 35px; width: 35px; border: 1px solid #878E99; border-radius: 50%;"
          class="text-blueCrayola text-center font-semibold py-1"
          v-else
        >
          {{ $getInitials(`${$AuthUser.fname} ${$AuthUser.lname}`) }}
        </div>
      </div>
      <div v-if="appPage === 'ESS'">
        <img
          v-if="$AuthUser.photo"
          :src="appPage === 'ESS' ? $AuthUser.photo : $OrgData.logoUrl"
          alt="account"
          style="height: 35px; width: 35px; border-radius: 50%;"
        />
        <div
          style="height: 35px; width: 35px; border-radius: 50%; border: 1px solid #878E99"
          class="text-blueCrayola text-center font-semibold py-1"
          v-else
        >
          {{ $getInitials(`${$AuthUser.fname} ${$AuthUser.lname}`) }}
        </div>
      </div>
    </template>
    <div style="width: 261px" class="py-5 px-4">
      <div class="flex justify-between h-4">
        <p
          v-if="appPage === 'Admin' && $AuthUser.isEmployee"
          @click="$router.push({ name: 'EssDashboard' })"
          class="text-flame font-bold cursor-pointer"
          style="font-size:10px"
        >
          SWITCH TO ESS CONSOLE
        </p>
        <p
          @click="$router.push({ name: 'AdminDashboard' })"
          class="text-flame font-bold cursor-pointer"
          v-if="
            appPage === 'ESS' && ($AuthUser.roles.humanar || $AuthUser.isRoot)
          "
          style="font-size:10px"
        >
          SWITCH TO ADMIN CONSOLE
        </p>
        <icon v-if="appPage === 'Admin'" size="xs" icon-name="settings" />
      </div>
      <div
        class="mt-2 pb-3 text-center"
        style="border-bottom: 0.5px solid rgb(135, 142, 153, 0.5)"
      >
        <div v-if="appPage === 'Admin'">
          <img
            v-if="$OrgData.logoUrl"
            :src="appPage === 'ESS' ? $AuthUser.photo : $OrgData.logoUrl"
            alt="account"
            style="height: 60px; width: 60px; border-radius: 50%; margin: 0 auto"
          />
          <div
            style="height: 60px; width: 60px; border-radius: 50%; margin: 0 auto"
            class="text-blueCrayola text-center font-semibold py-1"
            v-else
          >
            {{ $getInitials(`${$AuthUser.fname} ${$AuthUser.lname}`) }}
          </div>
        </div>
        <div v-if="appPage === 'ESS'">
          <img
            v-if="$AuthUser.photo"
            :src="appPage === 'ESS' ? $AuthUser.photo : $OrgData.logoUrl"
            alt="account"
            style="height: 60px; width: 60px; border-radius: 50%; margin: 0 auto"
          />
          <div
            style="height: 60px; width: 60px; border-radius: 50%; margin: 0 auto; border: 1px solid #878E99"
            class="text-blueCrayola text-center text-2xl font-semibold py-3"
            v-else
          >
            {{ $getInitials(`${$AuthUser.fname} ${$AuthUser.lname}`) }}
          </div>
        </div>
        <h1 class="text-lg font-bold capitalize">
          {{ `${$AuthUser.fname} ${$AuthUser.lname}` }}
        </h1>
        <h1 class="text-sm text-jet break-all">
          {{ $AuthUser.email }}
        </h1>
        <h1
          v-if="$AuthUser.userDesignation"
          class=" font-semibold capitalize text-romanSilver"
          style="font-size:10px"
        >
          {{ $AuthUser.userDesignation.name }}
        </h1>
      </div>
      <div
        class="py-3"
        style="border-bottom: 0.5px solid rgb(135, 142, 153, 0.5)"
      >
        <Card class="p-2">
          <h1
            class="text-mediumSeaGreen font-semibold text-left"
            style="font-size:10px"
          >
            OFFICE LOCATION
          </h1>
          <h1
            class="text-semibold text-left capitalize"
            v-if="$AuthUser.employeeOffice"
          >
            {{ $AuthUser.employeeOffice.name }}
          </h1>
          <h1 v-else>Not Assigned</h1>
          <div class="flex">
            <p class="text-sm" v-if="officeState">
              {{ officeState.name }}
            </p>
          </div>
        </Card>
      </div>
      <div class="mt-3">
        <div
          class="flex mt-3 h-4"
          :class="$AuthUser.isRoot ? 'justify-between' : 'justify-end'"
          style="font-size:10px;"
        >
          <div
            v-if="$AuthUser.isRoot"
            @click="handleManageSub"
            class="flex text-blueCrayola cursor-pointer"
          >
            <icon icon-name="open_in_new" class="mr-2" size="xxs" />
            <p>MANAGE SUBSCRIPTION</p>
          </div>
          <div @click="$handleLogout" class="flex text-desire cursor-pointer">
            <p>LOGOUT</p>
            <icon icon-name="logoff" class="ml-2" size="xxs" />
          </div>
        </div>
      </div>
    </div>
  </Menu>
</template>

<script>
import Menu from "@/components/Menu";
import Card from "@/components/Card";
import state from "@/store/state";
import Icon from "@/components/Icon";

export default {
  name: "AppProfile",
  components: {
    Icon,
    Menu,
    Card
  },
  props: {
    appPage: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      initials: "",
      officeState: ""
    };
  },
  computed: {},
  methods: {
    handleManageSub() {
      return window.location.replace(
        `${process.env.VUE_APP_CLOUDENLY}subscriptions/management`
      );
    }
  },
  mounted() {
    if (this.$AuthUser.employeeOffice !== null) {
      this.officeState = state.states.find(
        _state => _state.id === this.$AuthUser.employeeOffice.state
      );
    }
  }
};
</script>

<style></style>
